import React from "react"

import colors from "../colors"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import PersonList from "../components/person/PersonList"
import Map from "../components/map"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const About = () => {
  return (
    <PageLayout>
      <SEO
        title="About The Long Term Care Insurance Group and Corey Rieck"
        ogDescription="Corey Rieck started The Long Term Care Planning Group to provide you with the best information and placement with your long term care insurance policy."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Corey Rieck and The Long Term Care Planning Group</h1>
            <p>
              Our clients find our confidential and consultative approach we take reassuring. They know we will achieve the best possible outcome for them as their long term care planning expert.
            </p>
            <p>
As long term care planning experts, we only present you with what is most amenable to your medical history. If there is no medical history, you will likely have a lot of options. If there is medical history, it almost always requires a second strategy for us to find a carrier more amenable to your medical history.
            </p>
            <p>
We have working relationships with all the major carriers in the long term care marketplace including TransAmerica and Chubb. We only care that our clients have options and will work with carriers to find the best match based on how he or she presents medically.  
            </p>
            <RegisterForWebinarButton />
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/corey-rieck-long-term-planning-group.jpg"
              alt="Corey Rieck of the Long Term Care Insurance Group"
            />
          </div>
        }
      />
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList />
      </PageWrapper>
      <Map />
    </PageLayout>
  )
}

export default About
